// import { t } from '@lingui/macro'
import { Link } from 'react-router-dom';
import { Logo } from '../logo';
import { HeroCTA } from '@/client/pages/home/sections/hero/call-to-action';
import BannerSectionCards from './BannerSectionCards';
import templateIcon1 from '@/client/assets/images/templateIcon1.svg'
import templateIcon2 from '@/client/assets/images/templateIcon2.svg'
import templateIcon3 from '@/client/assets/images/templateIcon3.svg'
import bannerRocket from '@/client/assets/images/bannerRocket.png';
import logoWhite from '@/client/assets/LogoLight.svg'

const BannerSection = () => {

  const createResumeSteps = [
    {
      icon: templateIcon1,
      title: 'Fill the details',
      desc: `Enter your personal information, education, work experience, skills, 
      and other relevant details to complete your resume profile.`
    },
    {
      icon: templateIcon2,
      title: 'Choose a template',
      desc: `Select from a variety of professionally designed templates to find 
      the one that best showcases your information and suits your style.`
    },
    {
      icon: templateIcon3,
      title: 'Launch your Resume',
      desc: `Review your completed resume and make any final adjustments. 
      Once satisfied, download, print, or share your polished CV with just a click.`
    },
  ]

  return (
    <section id="banner" className="relative z-50 bg-[#000000]">
      <div className="mx-auto w-full">
        <div className='px-6 pl-[1rem] xs:pl-[2rem] md:pl-[4rem] lg:pl-[5rem] xl:pl-[9rem] pt-[1%]'>
          <Link to="/" className=''>
          <img
            src={logoWhite}
            width={180}
            height={180}
            alt="Rocket Resume"
            className="rounded-sm max-w-[25%] sm:max-w-[50%] md:max-w-[100%]"
          />
            {/* <Logo size={180} className='max-w-[25%] sm:max-w-[50%] md:max-w-[100%]'/> */}
          </Link>
        </div>
        <div className="h-[35rem] xs:h-[40rem] sm:h-[20rem] md:h-[26rem] lg:h-[32rem] xl:h-[39rem] flex flex-col sm:flex-row justify-between">
          <div className="h-[55%] xs:h-[40%] sm:h-[100%] w-[100%] sm:w-[55%] lg:w-[50%] xl:w-[45%] pt-[2.5rem] xs:pt-[3rem] xl:pt-[6rem] 2xl:pt-[10rem] pl-[1rem] xs:pl-[2rem] md:pl-[4rem] lg:pl-[5rem] xl:pl-[9rem] md:pr-[2rem] 2xl:pr-[6rem] 3xl:pr-[10rem] bg-cover" style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 100%), url(${bannerRocket})` }}>
            <div className="font-ibm-plex-sans text-[#FFFFFF] text-[1.4rem] md:text-[1.8rem] lg:text-[2.5rem] font-bold leading-[24px] md:leading-[40px] lg:leading-[50px] pr-[1rem] 2xl:pr-[2rem] pb-[8px] xs:pb-[18px] lg:pb-[25px]">
              {`Elevate Your`}&nbsp; <span className={`text-[#FFEB00]`}>{`Career`}</span><br />{`with a Professionally Crafted CV.`}
            </div>
            <div className='font-ibm-plex-sans text-[#C8C8CB] text-[0.8rem] md:text-[0.9rem] lg:text-base font-normal leading-[18px] md:leading-[20px] lg:leading-[22px]'>
              {`Build your perfect CV effortlessly with our intuitive resume builder. Simply fill in your details, choose from a variety of professionally designed templates, and launch a polished resume in minutes. Our platform offers user-friendly features, customization options, and easy sharing tools to help you stand out to potential employers. Create a resume that highlights your strengths and makes a lasting impression.`}
            </div>
            <div className="my-2 xs:my-4 flex justify-center sm:justify-start ">
              <HeroCTA />
            </div>
          </div>
          <div className="h-[55%] xs:h-[60%] sm:h-[100%] w-[100%] sm:w-[45%] lg:w-[50%] mr-[4rem] md:mr-0 bg-[url('@/client/assets/images/rocketInLaptop_4x.png')] bg-contain bg-no-repeat"></div>
        </div>
        <div className="bg-[#121211] px-[2rem] lg:px-[5rem] xl:px-[9rem] pt-[4%] pb-[4%]" style={{ clipPath: 'polygon(0 100%, 750% 100%, 0 0)' }}>
          <h1 className='text-[1.3rem] text-[#FFFFFF] xs:text-[1.8rem] lg:text-[2.5rem] mt-[60px] lg:mt-[16px] xl:text-5xl font-semibold text-center'>{`Create your Resume in three easy steps`}</h1>
          <div className='flex flex-col xs:flex-row justify-between gap-4 lg:gap-7 mt-[5%]'>
            {createResumeSteps?.map((item: { icon: any, desc: string, title: string }, key: number) =>
              <BannerSectionCards
                key={key}
                id={key + 1}
                src={item?.icon}
                title={item?.title}
                desc={item?.desc}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerSection